import * as ko from "knockout";

import { WidgetsConfiguration } from "types/Models";

import { IEventAggregator } from "app/common/EventAggregator";

import { MyPageWidgetDefinition } from "app/models/MyPageWidgetDefinition";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { MyPageWidget } from "app/models/MyPageWidget";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { ITranslationService } from "app/models/TranslationService";
import { PlaceableWidgetFactoryBase as WidgetFactoryBase } from "./PlaceableWidgetFactoryBase";

export class MyPageWidgetFactory extends WidgetFactoryBase implements IWidgetFactory {
	private translationService;
	private readonly shoppingFacade: ShoppingFacade;
	private readonly eventAggregator: IEventAggregator;
	private readonly configuration: WidgetsConfiguration;

	constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, configuration: WidgetsConfiguration, translationService: ITranslationService) {
		super();
		this.translationService = translationService;

		this.shoppingFacade = shoppingFacade;
		this.eventAggregator = eventAggregator;
		this.configuration = configuration;
	}

	build(definition: MyPageWidgetDefinition) {
		var element = this.getElement(definition);
		var itembody = document.getElementById("skfWidget__mypage").innerText;
		element.innerHTML = itembody;

		try {
			ko.applyBindings(new MyPageWidget(
				this.shoppingFacade,
				this.eventAggregator,
				this.configuration,
				this.translationService), element);

		} catch (e) {
			console.error(e);
		}
	}

}