import Promise from "ts-promise";
import { TrackingModel } from "app/common/tracking/TrackingModels";
declare global {
    interface Window { dataLayer: any; }
}

export interface IEventTracker {
    track(event: TrackingEvent): Promise<boolean>;
}

export class TrackingEvent {
    constructor(eventName: string, model: TrackingModel) {
        this.eventName = eventName;
        this.model = model;
    }

    eventName: string;
    model: any;
}

export class DummyEventTracker implements IEventTracker {
    track(event: TrackingEvent): Promise<boolean> {
        const deferred = Promise.defer<boolean>();

        setTimeout(() => {
            deferred.resolve(true);
            console.log(event);
        }, 3000);
        return deferred.promise;
    }

}

export class GoogleTracker implements IEventTracker {
    track(event: TrackingEvent): Promise<boolean> {
        const deferred = Promise.defer<boolean>();

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: event.eventName,
            ecommerce: event.model,
            eventTimeout: 200,
            eventCallback: () => {
                deferred.resolve(true);
            }
        });

        return deferred.promise;
    }
}
