import { IEventAggregator } from "app/common/EventAggregator";
import { UrlResolver } from "app/common/UrlResolver";
import { UniqueIdentifierGenerator } from "app/common/UniqueIdentifierGenerator";

import { WidgetsConfiguration } from "types/Models";

import { ITranslationService } from "app/models/TranslationService";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { WidgetType } from "app/models/WidgetType";
import { WidgetDefinition } from "app/models/WidgetDefinition";
import { ProductWidgetFactory } from "app/models/ProductWidgetFactory";
import { CartWidgetFactory } from "app/models/CartWidgetFactory";
import { UpsellWidgetFactory } from "app/models/UpsellWidgetFactory";
import { MyPageWidgetFactory } from "app/models/MyPageWidgetFactory";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { UpsellWidget } from "app/models/UpsellWidget";
import { GlobalLoaderWidgetFactory } from "app/models/GlobalLoaderWidgetFactory";
import { GlobalLoaderWidget } from "app/models/GlobalLoaderWidget";

export class WidgetBuilder {

    factories: { [index: string]: IWidgetFactory } = {};

    constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, translationService: ITranslationService, urlResolver: UrlResolver, configuration: WidgetsConfiguration) {

        const loader = this.setUpGlobalLoaderWidget();
        const uppsell = this.setUpUpsellWidget(shoppingFacade, eventAggregator, translationService, configuration, loader);
        
        this.factories[WidgetType.product] = new ProductWidgetFactory(shoppingFacade, eventAggregator, translationService, urlResolver, configuration, uppsell, loader);
		this.factories[WidgetType.cart] = new CartWidgetFactory(shoppingFacade, eventAggregator, configuration);
		this.factories[WidgetType.mypage] = new MyPageWidgetFactory(shoppingFacade, eventAggregator, configuration, translationService);
    }
    
    build(definition: WidgetDefinition) : any {
        if (this.factories[definition.widgetType] == null) {
            throw new Error("No factory registered for type: " + definition.widgetType);
        }

        return this.factories[definition.widgetType].build(definition);
    }

    private setUpUpsellWidget(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator,  translationService: ITranslationService, configuration: WidgetsConfiguration, loader: GlobalLoaderWidget): UpsellWidget {

        const factory = new UpsellWidgetFactory(shoppingFacade, eventAggregator, translationService, configuration, loader);

        const conatiner = document.createElement("div");
        conatiner.id = "skfWidget__upsell__" + UniqueIdentifierGenerator.generate();
        document.body.appendChild(conatiner);

        return factory.build(new WidgetDefinition(conatiner.id, null, WidgetType.upsell)) as UpsellWidget;
    }

    private setUpGlobalLoaderWidget(): GlobalLoaderWidget  {
        const factory = new GlobalLoaderWidgetFactory();

        const conatiner = document.createElement("div");
        conatiner.id = "skfWidget__loader__global__" + UniqueIdentifierGenerator.generate();
        document.body.appendChild(conatiner);

        return factory.build(new WidgetDefinition(conatiner.id, null, WidgetType.globalLoader)) as GlobalLoaderWidget;
    }
}