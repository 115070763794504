
export class Enumerable<T> {

    private array: Array<T>;

    constructor(array: Array<T>) {
        this.array = array;
    }

    toArray(): Array<T> {
        return this.array.slice();
    }
    
    excechange(): Enumerable<T> {
        return new Enumerable(this.array.splice(0, this.array.length));
    }

    contains(item: T): boolean {
        for (let i = 0; i < this.array.length; i++) {
            if (this.array[i] === item) {
                return true;
            }
        }

        return false;
    };

    containsWithCommparer(compare): boolean {
        for (let i = 0; i < this.array.length; i++) {
            if (compare(this.array[i])) {
                return true;
            }
        }
        return false;
    };

    groupBy(predicate) {

        var groups = [];
        for (let i = 0; i < this.array.length; i++) {
            let resolvedPredicate = predicate(this.array[i]);

            if (groups[resolvedPredicate] === undefined) {
                groups[resolvedPredicate] = [];
            }

            groups[resolvedPredicate].push(this.array[i]);
        }

        return groups;
    };
    
    indexOfElement(selector): number {

        let comparer;
        if (typeof (selector) !== "function") {
            comparer = e => e === selector;
        }
        else {
            comparer = selector;
        }

        for (let i = 0; i < this.array.length; i++) {
            if (comparer(this.array[i]) === true) {
                return i;
            }
        }

        return -1;
    };

    remove(item: T): void {
        var newArray = new Array();

        const length = this.array.length;
        for (let i = length - 1; i >= 0; i--) {
            if (this.array[i] !== item) {
                newArray.push(this.array[i]);
            }

            this.array.pop();
        }

        for (var z = newArray.length - 1; z >= 0; z--) {
            this.array.push(newArray[z]);
        }
    };

    pushRange(items: Array<T>): Enumerable<T> {
        for (let i = 0; i < items.length; i++) {
            this.array.push(items[i]);
        }
        return this;
    };

    removeAt(from: number, to?: number): void {
        const rest = this.array.slice((to || from) + 1 || this.array.length);
        this.array.length = from < 0 ? this.array.length + from : from;
        this.array.push.apply(this.array, rest);
    };

    sum(selector): number {
        if (selector == undefined) {
            selector = element => element;
        }

        var sum = 0;
        for (let i = 0; i < this.array.length; i++) {
            sum += selector(this.array[i]);
        };

        return sum;
    };

    push(item: T): number {
        return this.array.push(item);
    }

    distinct(): Enumerable<T> {

        var items = new Enumerable<T>([]);

        for (var i = 0; i < this.array.length; i++) {
            if (items.contains(this.array[i]) === false) {
                items.push(this.array[i]);
            }
        }

        return items;
    };

    countMatch(element): number {
        var count = 0;

        for (let i = 0; i < this.array.length; i++) {
            if (this.array[i] === element) {
                count++;
            }
        }

        return count;
    };

    count(selector? : (item: T, index?: number) => boolean): number {

        if (selector == null) {
            return this.array.length;
        }

        let count = 0;

        for (let i = 0; i < this.array.length; i++) {
            if (selector(this.array[i], i)) {
                count++;
            }
        }

        return count;
    };

    clear() {
        this.array.length = 0;
    };

    firstOrDefault(selector: (item: T) => boolean): T {
        if (selector == undefined) {
            selector = () => true;
        }

		for (let i = 0; i < this.array.length; i++) {
			
            if (selector(this.array[i])) {
                return this.array[i];
            }
        }

        return null;
    };

    skip(numberOfElements: number): Enumerable<T> {
        var array = new Array<T>();

        for (let i = 0; i < this.array.length; i++) {
            if (i >= numberOfElements) {
                array.push(this.array[i]);
            }
        }

        return new Enumerable(array);
    };

    take(numberOfElements: number): Enumerable<T> {
        var array = new Array<T>();

        for (let i = 0; i < this.array.length; i++) {
            if (i < numberOfElements) {
                array.push(this.array[i]);
            } else {
                break;
            }
        }

        return new Enumerable(array);
    };

    first(selector: (item: T) => boolean): T {

        var errorMessge = "Secuence contains 0 elements";

        if (selector == undefined) {
            if (this.array.length === 0) {
                throw errorMessge;
            }
            else {
                return this.array[0];
            }
        }

        var element = this.firstOrDefault(selector);

        if (element == null) {
            if (this.array.length === 0) {
                throw errorMessge;
            }
            else {
                return this.array[0];
            }
        }

        return element;
    };

    select<TResult>(selector : (item: T, index?: number) => TResult): Enumerable<TResult> {
        if (selector == undefined || typeof (selector) != 'function') {
            throw "Selector must be definied as function";
        }

        var items = new Array();

        for (let i = 0; i < this.array.length; i++) {
            items.push(selector(this.array[i], i));
        }

        return new Enumerable(items);
    };

    selectMany(selector): Enumerable<T> {
        if (selector == undefined || typeof (selector) != 'function') {

            throw "Selector must be definied as function";
        }

        var items = new Enumerable<T>([]);

        for (let i = 0; i < this.array.length; i++) {
            items.pushRange(selector(this.array[i]));
        }

        return items;
    };

    any(selector): boolean {
        var itemsSelector = selector;
        if (selector === undefined || typeof (selector) !== 'function') {
            itemsSelector = e => true;
        }

        for (let i = 0; i < this.array.length; i++) {

            if (itemsSelector(this.array[i], i) === true) {
                return true;
            }
        }

        return false;
    };

    all(selector): boolean {
        var itemsSelector = selector;
        if (selector === undefined || typeof (selector) !== 'function') {
            itemsSelector = e => true;
        }

        for (let i = 0; i < this.array.length; i++) {
            if (itemsSelector(this.array[i], i) === false) {
                return false;
            }
        }

        return true;
    };

    where(selector: (item: T, index?: number) => boolean): Enumerable<T> {
        if (selector == undefined || typeof (selector) != 'function') {
            throw "Selector must be definied as function";
        }

        const items = new Enumerable<T>([]);
        for (let i = 0; i < this.array.length; i++) {
            if (selector(this.array[i], i)) {
                items.push(this.array[i]);
            }
        }

        return items;
    };

    joinStrings(anSeparator? : string): string {
        var separator = anSeparator;
        if (separator == undefined)
            separator = "";

        let result = "";

        for (let i = 0; i < this.array.length; i++) {
            result += this.array[i];

            if (i < this.array.length - 1) {
                result += separator.toString();
            }
        }

        return result;
    };

    forEach(action : (item: T, index?: number) => void): Enumerable<T> {

        for (let i = 0; i < this.array.length; i++) {
            action(this.array[i], i);
        }

        return this;
    };

    max(selector): number {
        if (selector === undefined || selector === null || typeof (selector) != 'function') {
            throw "Selector must be definied as function";
        }

        if (this.array.length === 0) {

            throw "there is no elements to calculate max value";
        }

        let max = selector(this.array[0], 0);

        for (let i = 1; i < this.array.length; i++) {
            const value = selector(this.array[i], i);
            if (value > max) {
                max = value;
            }
        }

        return max;
    };

    maxOrDefault(selector, defaultMaxValue): number {
        if (selector === undefined || selector === null || typeof (selector) != 'function') {
            throw "Selector must be definied as function";
        }

        if (this.array.length === 0) {
            return defaultMaxValue;
        }

        return this.max(selector);
    };

    minBy(fn): number { return this.extremumBy(fn, Math.min); };

    maxBy(fn): number { return this.extremumBy(fn, Math.max); };

    extremumBy(pluck, extremum) {
        return this.array.reduce((best, next) => {
            var pair = [pluck(next), next];
            if (!best) {
                return pair;
            } else if (extremum.apply(null, [best[0], pair[0]]) == best[0]) {
                return best;
            } else {
                return pair;
            }
        }, null)[1];
    }
    
    min(selector): number {
        if (selector === undefined || selector === null || typeof (selector) != 'function') {
            selector = e => e;
        }

        if (this.array.length === 0) {
            throw "there is no elements to calculate min value";
        }

        let min = selector(this.array[0], 0);

        for (let i = 1; i < this.array.length; i++) {
            const value = selector(this.array[i], i);
            if (value < min) {
                min = value;
            }
        }

        return min;
    };

    lastOrDefault(selector): T {

        if (selector == undefined) {
            selector = () => true;
        }

        for (let i = this.array.length - 1; i >= 0; i--) {
            if (selector(this.array[i], i) === true) {
                return this.array[i];
            }
        }

        return null;
    };

    last(selector): T {

        const errorMessge = "Secuence contains 0 elements";
        const element = this.lastOrDefault(selector);

        if (element == null) {
            throw errorMessge;
        }

        return element;
    };
}
