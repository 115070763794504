import * as ko from "knockout";

import { WidgetsConfiguration } from "types/Models";

import { IEventAggregator } from "app/common/EventAggregator";

import { CartWidgetDefinition } from "app/models/CartWidgetDefinition";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { CartWidget } from "app/models/CartWidget";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { WidgetDefinition } from "app/models/WidgetDefinition";


export abstract class PlaceableWidgetFactoryBase implements IWidgetFactory {

	abstract build(definition: WidgetDefinition);

	getElement(definition: WidgetDefinition): HTMLElement {

		if (definition.node == null && definition.containerId == null)
			  throw new Error("Argument exception. Widget container does not exist");

		if (definition.node != null) {
			return definition.node;
		} else {
			return document.getElementById(definition.containerId);
		}

	}

	applyBindings(model: any, definition: WidgetDefinition) {
		if (definition.node != null) {
			ko.applyBindings(model, definition.node);
		} else {
			ko.applyBindings(model, document.getElementById(definition.containerId));
		}
	}

}