import * as ko from 'knockout';

export class WidgetErrorHandler  {

	errorMessage: KnockoutObservable<string>;
	isVisible: KnockoutComputed<boolean>;
	constructor() {
		this.errorMessage = ko.observable<string>("");
		this.isVisible = ko.computed(() => {
			return this.errorMessage() !== "";
		});
	}

	handle(message: string) {
		this.errorMessage(message);
	}

	clear() {
		this.errorMessage("");
	}
}