import { IUrlResolver } from "app/common/UrlResolver";
import * as ko from 'knockout';

export class AbsoluteSrc implements KnockoutBindingHandler<Element, any, any> {
    
    private static urlResolver: IUrlResolver;

    constructor(urlResolver: IUrlResolver) {
        AbsoluteSrc.urlResolver = urlResolver;
    }

    init?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext): void {
    }

    update?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext) : void {

        const url = ko.unwrap(valueAccessor());
        const absoluteUrl = AbsoluteSrc.urlResolver.getAbsoluteUrl(url);

        element.setAttribute("src", absoluteUrl);
    };
}


export interface IKnockoutBindingHandlers extends KnockoutBindingHandlers {
    absoluteSrc: KnockoutBindingHandler;
}