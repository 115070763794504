import { IEventAggregator } from "app/common/EventAggregator";

import * as ko from 'knockout';

import { ProductAdapter } from "app/models/ProductAdapter";
import { ITranslationService } from "app/models/TranslationService";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { IUpsellService } from "app/models/UpsellWidget";
import { ILoadingIndicator } from "app/models/GlobalLoaderWidget";

import { EventTypes } from "app/models/ShoppingFacade";
import { WidgetErrorHandler } from "app/models/WidgetErrorHandler";

export class ProductWidget {
    
    private readonly articleNumber: string;
    private readonly shoppingFacade: ShoppingFacade;
    private readonly eventAggregator: IEventAggregator;
    private readonly upsellService: IUpsellService;
    private readonly loader: ILoadingIndicator;
    private readonly translationService: ITranslationService;

    public readonly  resellerUrl: string;
    public errorHandler: WidgetErrorHandler;
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public product: KnockoutObservable<ProductAdapter> = ko.observable(null);
    public addToCartLabel: string;
	public findReselersLabel: string;

    public freightInfoLinkText: string;
	public unitOfMeasurePriceLabel: string;

	
	public notAvailableForSalelabel : string;
    public avaiableForSale: KnockoutObservable<boolean> = ko.observable(false); 
	public outOfStock: KnockoutObservable<boolean> = ko.observable(false);
	constructor(articleNumber: string, resellerUrl: string,  shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, translationService: ITranslationService, upsellService: IUpsellService, loader: ILoadingIndicator) {

        this.articleNumber = articleNumber;
        this.resellerUrl = resellerUrl;
        this.shoppingFacade = shoppingFacade;
        this.eventAggregator = eventAggregator;
        this.translationService = translationService;
        this.upsellService = upsellService;
		this.loader = loader;
	
	    this.errorHandler = new WidgetErrorHandler();
        this.addToCartLabel = this.translationService.translate("/product/addToCartButtonLabel");
		this.findReselersLabel = this.translationService.translate("/product/findReselersButtonLabel");

		this.freightInfoLinkText = this.translationService.translate("/product/freightInfoLinkText");
		this.unitOfMeasurePriceLabel = this.translationService.translate("/product/unitOfMeasurePriceLabel");

		this.notAvailableForSalelabel = this.translationService.translate("/product/notAvailableForSalelabel");

		this.isLoading(true);
	    this.getProducts(articleNumber);

	    this.eventAggregator.subscribe(
		    EventTypes.Added,
		    (r) => this.getProducts(articleNumber),
		    this);

	    this.eventAggregator.subscribe(
		    EventTypes.QuantityChanged,
		    () => this.getProducts(articleNumber),
			this);

	    this.eventAggregator.subscribe(
		    EventTypes.Removed,
		    () => this.getProducts(articleNumber),
		    this);
    }

	private getProducts(articleNumber: string) {
		this.errorHandler.clear();

		this.shoppingFacade .getProduct(articleNumber)
			.then((p) => {

					if (p != null) {
					    try {
					        var productItem = new ProductAdapter(p, this.translationService);
					        this.product(productItem);
							this.avaiableForSale(productItem.isAvaillableForSale);
					        this.outOfStock(productItem.isInStock === false);
					    } catch (e) {
					        this.errorHandler.handle(e.message);
					        console.error(e);
                        } 
					}
					this.isLoading(false);
				},
			(e) => {
					this.errorHandler.handle(e.message);
					this.isLoading(false);
					console.error(e);
				});
	}


	addToCart() {

        this.loader.show();
		this.errorHandler.clear();

        this.shoppingFacade.addToCart(this.articleNumber).then(r => {
                this.loader.hide();
            try {
                this.upsellService.show(r);
                this.eventAggregator.publish(EventTypes.Add, this.product());
			} catch (e) {
	            this.errorHandler.handle(e.message);
                console.error(e);
                throw e;
            }
            
        },
            e => {
				this.loader.hide();
				this.errorHandler.handle(e.message);
                console.error(e);
            });
    }
}