import * as ko from 'knockout';
import { CartModel, CartItemModel, PriceModel, Guid } from "types/Models";
import { AddToCartResponse, WidgetsConfiguration } from "types/Models";

import { ITranslationService } from "app/models/TranslationService";
import { IEventAggregator } from "app/common/EventAggregator";
import { ShoppingFacade } from "app/models/ShoppingFacade";

import { EventTypes } from "app/models/ShoppingFacade";




export class MyPageWidget {
	private readonly translationService: ITranslationService;
	private readonly shoppingFacade: ShoppingFacade;
	private readonly eventAggregator: IEventAggregator;
	private readonly configuration: WidgetsConfiguration;

	isLoggedIn: KnockoutObservable<boolean>;
	myPageLink: string;
	myPageLabel: string;
	constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, configuration: WidgetsConfiguration, translationService: ITranslationService) {
		this.translationService = translationService;
		this.shoppingFacade = shoppingFacade;
		this.eventAggregator = eventAggregator;
		this.isLoggedIn = ko.observable(false);
		this.myPageLabel = translationService.translate("/mypage/label");
		this.myPageLink = configuration.myPageUrl;

		shoppingFacade.loginStatus().then(
			r => this.isLoggedIn(r),
			e => console.error(e));

	}

}
