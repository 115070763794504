import Promise from "ts-promise";
import { ProductModel } from "types/Models";
import { CartModel } from "types/Models";
import { AddToCartRequest } from "types/Models";
import { AddToCartResponse } from "types/Models";
import { RemoveCartItemRequest } from "types/Models";
import { UpdateCartItemRequest } from "types/Models";
import { CartItemChangeResponse } from "types/Models";
import { WidgetsConfiguration } from "types/Models";
import { ProductsRequest } from "types/Models";
import { Guid } from "types/Models";
import { AjaxRequestSender } from "app/common/ajax/AjaxRequestSender";

export interface IShoppingServiceProxy {
    getConfiguration(): Promise<WidgetsConfiguration>;
    getProduct(code: string): Promise<ProductModel>;
    getProducts(codes: Array<string>): Promise <Array<ProductModel>>;
    getCart(): Promise <CartModel>;
    addToCart(articleNumber: string): Promise <AddToCartResponse>;
    removeFromCart(identifier: Guid, articleNumber: string): Promise <CartItemChangeResponse>;
    updateItemQuantityinCart(identifier: Guid, articleNumber: string, quantity: number): Promise <CartItemChangeResponse>;
	loginStatus(): Promise<boolean>;
}

export class ShoppingServiceProxy  {

    baseServerUrl : string;

    constructor(baseUrl: string) {
        this.baseServerUrl = baseUrl;
        this.keepAlive();
    }

    getConfiguration(): Promise<WidgetsConfiguration> {
        var sender = new AjaxRequestSender(this.baseServerUrl + "widgetsettings");
        return sender.send("GET");
    }

    public getProduct(code : string) : Promise<ProductModel> {
        var sender = new AjaxRequestSender(this.baseServerUrl + "api/product/?articleNumber=" + code);
        return sender.send("GET");
    }

    public getProducts(codes: Array<string>): Promise<Array<ProductModel>> {
        var sender = new AjaxRequestSender(this.baseServerUrl + "api/product/getProducts/");

        var request: ProductsRequest = {
            articleNumbers: codes
        };

        return sender.send("POST", JSON.stringify(request));
    }

    public getCart(): Promise<CartModel> {
        var sender = new AjaxRequestSender(this.baseServerUrl + "api/widget/cart/");
        return sender.send("GET");
    }

    public addToCart(articleNumber: string): Promise<AddToCartResponse> {
        var request: AddToCartRequest = {
            articleNumber: articleNumber
        };

        var sender = new AjaxRequestSender(this.baseServerUrl + "api/widget/cart/");
        return sender.send("POST", JSON.stringify(request));
    }

    public removeFromCart(identifier: Guid, articleNumber: string): Promise<CartItemChangeResponse> {
        var request: RemoveCartItemRequest = {
            articleNumber: articleNumber,
            id: identifier
        };

        var sender = new AjaxRequestSender(this.baseServerUrl + "api/widget/cart/");
        return sender.send("DELETE", JSON.stringify(request));
    }

    public updateItemQuantityinCart(identifier: Guid, articleNumber: string, quantity: number): Promise<CartItemChangeResponse> {
        var request: UpdateCartItemRequest = {
            articleNumber: articleNumber,
            id: identifier,
            quantity: quantity
        };

        var sender = new AjaxRequestSender(this.baseServerUrl + "api/widget/cart/");
        return sender.send("PUT", JSON.stringify(request));
    }

	public loginStatus(): Promise<boolean> {
		var sender = new AjaxRequestSender(this.baseServerUrl + "api/account/loginstatus");
		return sender.send("GET");
	}


    private keepAlive(): void {

        setInterval(() => {
                var sender = new AjaxRequestSender(this.baseServerUrl + "api/widget/cart/ping");
                sender.send("GET");
            },
            60000);
    }
}