import { AjaxProxy } from "app/common/ajax/AjaxProxy";
import Promise from "ts-promise";

export class TemplateLoader {
	
	ajaxMethod: AjaxProxy<any, any>;

	load(url: string): Promise<string> {

        var deferred = Promise.defer<string>();

	    this.ajaxMethod = this.initProxy(url);
        this.ajaxMethod.execute(null).then(r => {
            this.onRequestCompleted(r);
            deferred.resolve(r);
        }, r => deferred.reject(r));

		return deferred.promise;
	}

	initProxy<TInput, TOutput>(url:string): AjaxProxy<TInput, TOutput> {
        var proxy = new AjaxProxy<TInput, TOutput>(
            "GET",
			url
		);
		return proxy;
	}

	onRequestCompleted(data: string) {
		var template = document.createElement('div'); // is a node
		template.innerHTML = data;

		document.body.insertBefore(template, document.body.firstChild);
	}
}