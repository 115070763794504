import { Enumerable } from "app/common/Enumerable";

export interface IUrlResolver {
    getAbsoluteUrl(path: string);
}

export class UrlResolver  {
    private baseUrl: string;
    private version: string;

    constructor(baseUrl: string, version: string) {
        this.baseUrl = this.trimSlashes(baseUrl);
        this.version = this.trimSlashes(version);
    }

    private trimSlashes(path: string) {
        if (path.lastIndexOf("/") === path.length - 1) {
            path = path.substr(0, path.length - 1);
        }

        if (path.indexOf("/") === 0) {
            path = path.substr(1, path.length);
        }

        return path;
    }
    
    getAbsoluteUrl(path: string) {
        if (path == null || path === "") {
            throw new Error("path can't empty");
        }

        if (path.indexOf("http") === 0) {
            throw new Error(`Provided path is already absolute. Path: ${path}`);
        }

        path = this.trimSlashes(path);

        return new Enumerable([this.baseUrl, this.version, path]).joinStrings("/");
    }

}