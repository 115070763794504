import { IEventAggregator } from "app/common/EventAggregator";
import { EventTypes } from "../../models/ShoppingFacade";
import { GlobalTracker } from "./GlobalTracker";
import { TrackingProductActionModel, TrackingProductModel, ImpressionModel, CartAddActionModel, CartRemoveActionModel, TrackingProducts} from "./TrackingModels";
import { ProductModel, StockInformationModel, PriceModel, CartItemChangeResponse, AddToCartResponse, CartModel, CartItemModel } from "../../../types/Models";
import { StockStatus} from  "../../../types/Enums";
import { ProductAdapter } from "../../models/ProductAdapter";
import { Enumerable } from "app/common/Enumerable";

export enum Actions {
    "Add" = "add",
    "FetchedCart" = "fetchedCart",
    "AddToCart" = "addToCart",
    "RemoveFromCart" = "removeFromCart",
    "QuantityChanged" = "quantityChanged"
}

function diffCartItems(otherArray: CartItemModel[]) {
    return function(current: CartItemModel) {
        return otherArray.filter(function(other) {
            return other.id == current.id;
        }).length == 0;
    };
}

export class TrackingHandler {
    private tracker: GlobalTracker;
    private readonly eventAggregator: IEventAggregator;
    private productAlreadyVisible: string[] = [];
    private productModelBuilder: ProductTrackingModelBuilder;
    private previousCart: CartModel;

    constructor(eventAggregator: IEventAggregator, tracker: GlobalTracker) {
        this.eventAggregator = eventAggregator;
        this.tracker = tracker;
        this.productModelBuilder = new ProductTrackingModelBuilder();
        this.eventAggregator.subscribe(EventTypes.FetchedCart, this.handleFetchedCart, this);
        this.eventAggregator.subscribe(EventTypes.Added, this.handleAdded, this);
        this.eventAggregator.subscribe(EventTypes.Removed, this.handleRemoved, this);
        this.eventAggregator.subscribe(EventTypes.QuantityChanged, this.handleQuantityChanged, this);
        this.eventAggregator.subscribe(EventTypes.Shown, this.handleShown, this);
        this.eventAggregator.subscribe(EventTypes.ProductsLoaded, this.handleLoaded, this);
        this.eventAggregator.subscribe(EventTypes.UpsellShow, this.handleUpsellShow, this);
    }

    handleFetchedCart(data: CartModel) {
        this.tracker.cartTracker.action.track(Actions.FetchedCart, data);
        this.previousCart = data;
    }

    handleAdded(data: AddToCartResponse) {
        const cartItem = { ...data.itemAdded, quantity: 1 };
        this.tracker.cartTracker.action.track(Actions.AddToCart,
            this.productModelBuilder.buildAddFromCartItemModel(cartItem));
        this.previousCart = data.cart;
    }

    handleRemoved(data: CartItemChangeResponse) {
        const cartItem = this.previousCart.items.filter(diffCartItems(data.cartModel.items))[0];
        this.tracker.cartTracker.action.track(Actions.RemoveFromCart,
            this.productModelBuilder.buildRemoveFromCartItemModel(cartItem));
        this.previousCart = data.cartModel;
    }

    handleQuantityChanged(data: CartItemChangeResponse): void {
        if (this.previousCart) {
            this.previousCart.items.forEach((item: CartItemModel, index: number) => {
                const a = item.quantity;
                const b = data.cartModel.items[index].quantity;
                const quantityDiff = Math.abs(a - b);

                if (!quantityDiff) {
                    return;
                }

                item.quantity = quantityDiff;

                const actionName: Actions = (b > a) ? Actions.AddToCart : Actions.RemoveFromCart;

                this.tracker.cartTracker.action.track(actionName,
                    actionName == Actions.AddToCart
                    ? this.productModelBuilder.buildAddFromCartItemModel(item)
                    : this.productModelBuilder.buildRemoveFromCartItemModel(item));
            });
        }
        this.previousCart = data.cartModel;
    }

    handleShown(data: ProductModel): void {
        if (this.productAlreadyVisible.indexOf(data.articleNumber) >= 0) {
            return;
        }
        this.productAlreadyVisible.push(data.articleNumber);
        this.tracker.productTracker.impression.track(this.productModelBuilder.buildImpression(data));
    }

    handleLoaded(data: ProductModel[]): void {
        let filteredArray: ProductModel[] = [];
        const items = new Enumerable(data);
        items.forEach(x => {

            if (this.productAlreadyVisible.indexOf(x.articleNumber) < 0) {
                filteredArray.push(x);
                this.productAlreadyVisible.push(x.articleNumber);
            }
        });

        if (filteredArray.length > 0) {
            this.tracker.productTracker.impression.track(this.productModelBuilder.buildImpressionArray(filteredArray));
        }
    }

    handleUpsellShow(data: ProductAdapter[]): void {
        this.tracker.productTracker.impression.track(
            this.productModelBuilder.buildImpressionFromAdapterArray(data,
                ProductTrackingModelBuilder.placementUpsell));
    }
}

export class ProductTrackingModelBuilder {

    static placementUpsell = "Upsell";
    static placementSearch = "SearchResult";

    getStock(price: PriceModel, stockInfo: StockInformationModel): string {
        if (price == null)
            return "noPrice";

        switch (stockInfo.stockStatus) {
        case StockStatus.High:
            return "inStock";
        case StockStatus.Low:
            return "lowStock";
        case StockStatus.Out:
            return "outStock";
        default:
            return null;
        }
    }

    buildImpression(data: ProductModel): ImpressionModel {
        return {
            currencyCode: data.price.currency,
            impressions: [
                {
                    id: data.articleNumber,
                    brand: data.brand,
                    category: data.type,
                    position: "",
                    //list: ProductTrackingModelBuilder.placementSearch,
                    name: data.name,
                    price: data.price == null ? null : data.price.discountedUnitPrice,
                    metric1: data.price == null || data.price.regularUnitPrice == null
                        ? null
                        : data.price.regularUnitPrice,
                    variant: data.articleNumber,
                    dimension2: data.type,
                    dimension3: this.getStock(data.price, data.stockInformation),
                    dimension4: data.productFamily
                } as TrackingProductModel
            ]
        } as ImpressionModel;
    }

    buildAddFromCartItemModel(data: CartItemModel): CartAddActionModel {
        return {
            currencyCode: data.price.currency,
            add: {
                products: [
                    {
                        name: data.name,
                        id: data.articleNumber,
                        price: data.price.regularUnitPrice,
                        brand: data.brand,
                        category: data.type,
                        variant: data.articleNumber,
                        quantity: data.quantity,
                        metric1: data.price == null || data.price.regularUnitPrice == null
                            ? null
                            : data.price.regularUnitPrice,
                        dimension2: data.type,
                        dimension3: this.getStock(data.price, data.stockInformation),
                        dimension4: data.productFamily
                    } as TrackingProductActionModel
                ]
            } as TrackingProducts
        } as CartAddActionModel;
    }

    buildRemoveFromCartItemModel(data: CartItemModel): CartRemoveActionModel {
        return {
            currencyCode: data.price.currency,
            remove: {
                products: [
                    {
                        name: data.name,
                        id: data.articleNumber,
                        price: data.price.regularUnitPrice,
                        brand: data.brand,
                        category: data.type,
                        variant: data.articleNumber,
                        quantity: data.quantity,
                        metric1: data.price == null || data.price.regularUnitPrice == null
                            ? null
                            : data.price.regularUnitPrice,
                        dimension2: data.type,
                        dimension3: this.getStock(data.price, data.stockInformation),
                        dimension4: data.productFamily
                    } as TrackingProductActionModel
                ]
            } as TrackingProducts
        } as CartRemoveActionModel;
    }

    buildImpressionArray(products: ProductModel[]) {
        if (products.length <= 0)
            return null;

        const items = new Enumerable(products);

        const itemsWithCurrency = items.firstOrDefault(p => p.price != null);
        const currency = itemsWithCurrency !== null ? itemsWithCurrency.price.currency : "";

        const trackingItems = items.select(data => {
            return {
                id: data.articleNumber,
                brand: data.brand,
                category: data.type,
                position: "",
                //list: ProductTrackingModelBuilder.placementSearch,
                name: data.name,
                price: data.price == null ? null : data.price.discountedUnitPrice,
                metric1: data.price == null || data.price.regularUnitPrice == null
                    ? null
                    : data.price.regularUnitPrice,
                variant: data.articleNumber,
                dimension2: data.type,
                dimension3: this.getStock(data.price, data.stockInformation),
                dimension4: data.productFamily
            } as TrackingProductModel;
        }).toArray();

        return {
            currencyCode: currency,
            impressions: trackingItems
        } as ImpressionModel;
    }

    buildImpressionFromAdapterArray(products: ProductAdapter[], source: string): ImpressionModel {
        if (products.length <= 0)
            return null;

        const items = new Enumerable(products);
        const itemsWithCurrency = items.firstOrDefault(p => p.price != null);
        const currency = itemsWithCurrency !== null ? itemsWithCurrency.price.currency : "";


        const trackingItems = items.select(data => {
            return {
                id: data.articleNumber,
                brand: data.brand,
                category: data.type,
                position: "",
                //list: source,
                name: data.name,
                price: data.price == null ? null : data.price.discountedUnitPrice,
                metric1: data.price == null || data.price.regularUnitPrice == null ? null : data.price.regularUnitPrice,
                variant: data.articleNumber,
                dimension2: data.type,
                dimension3: this.getStock(data.price, data.stockInformation),
                dimension4: data.productFamily
            } as TrackingProductModel;
        }).toArray();

        return {
            currencyCode: currency,
            impressions: trackingItems
        } as ImpressionModel;
    }
}


