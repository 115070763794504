import * as ko from "knockout";

import { WidgetsConfiguration } from "types/Models";

import { IEventAggregator } from "app/common/EventAggregator";

import { CartWidgetDefinition } from "app/models/CartWidgetDefinition";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { CartWidget } from "app/models/CartWidget";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { PlaceableWidgetFactoryBase as WidgetFactoryBase } from "./PlaceableWidgetFactoryBase";

export class CartWidgetFactory extends WidgetFactoryBase implements IWidgetFactory {

    private readonly shoppingFacade: ShoppingFacade;
    private readonly eventAggregator: IEventAggregator;
    private readonly configuration: WidgetsConfiguration;

	constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, configuration: WidgetsConfiguration) {
		super();

		this.shoppingFacade = shoppingFacade;
		this.eventAggregator = eventAggregator;
		this.configuration = configuration;
	}

	build(definition: CartWidgetDefinition) {

        var element = this.getElement(definition);
        var itembody = document.getElementById("skfWidget__cart").innerText;
        element.innerHTML = itembody;
        
		try {

			ko.applyBindings(new CartWidget(
					this.shoppingFacade,
					this.eventAggregator,
                    this.configuration,
			        definition.continueShoppingUrl
			        ),
				element);
         
        } catch (e) {
            console.error(e);
        }
    }
    
}