import { WidgetsConfiguration } from "types/Models";

import * as ko from "knockout";

import { WidgetDefinition } from "app/models/WidgetDefinition";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { UpsellWidget } from "app/models/UpsellWidget";
import { IEventAggregator } from "app/common/EventAggregator";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { ITranslationService } from "app/models/TranslationService";
import { ILoadingIndicator } from "app/models/GlobalLoaderWidget";

export class UpsellWidgetFactory implements IWidgetFactory {

    private readonly shoppingFacade: ShoppingFacade;
    private readonly eventAggregator: IEventAggregator;
    private readonly translationService: ITranslationService;
    private readonly configuration: WidgetsConfiguration;
    private readonly loader: ILoadingIndicator;

    constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, translationService:  ITranslationService, configuration: WidgetsConfiguration, loader: ILoadingIndicator) {
        this.shoppingFacade = shoppingFacade;
        this.eventAggregator = eventAggregator;
        this.translationService = translationService;
        this.configuration = configuration;
        this.loader = loader;
    }

    build(definition: WidgetDefinition) : UpsellWidget {
        
        try {

            const element = document.getElementById(definition.containerId);

            element.innerHTML = document.getElementById("skfWidget__upsell").innerText;
            element.id = "";
            element.firstElementChild.id = definition.containerId;
            element.parentNode.replaceChild(element.firstElementChild, element);

            const model = new UpsellWidget(
                this.shoppingFacade,
                this.eventAggregator,
                this.translationService,
                this.configuration,
                this.loader);

            ko.applyBindings(model, document.getElementById(definition.containerId));

            return model;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}