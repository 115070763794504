import { ITranslationService } from "app/models/TranslationService";
import * as ko from 'knockout';

export class Translate implements KnockoutBindingHandler<Element, any, any> {
    
    private static translationService: ITranslationService;

    constructor(translationService: ITranslationService) {
        Translate.translationService = translationService;
    }

    init?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext): void {
    }

    update?(element: Element,
        valueAccessor: () => any,
        allBindingsAccessor: KnockoutAllBindingsAccessor,
        viewModel: any,
        bindingContext: KnockoutBindingContext) : void {

        let path = ko.unwrap(valueAccessor());
        let binding = 'text';

        if (typeof(path) != "string") {
            const data = <TranslateBinding>path;
            path = data.path;
            binding = data.binding;
        }

        const translation = Translate.translationService.translate(path);

        ko.bindingHandlers[binding].update(<any>element, () => translation, allBindingsAccessor, viewModel, bindingContext);
    };
}

export class TranslateBinding {
    path: string;
    binding: string;
}


export interface ITranslateKnockoutBindingHandlers extends KnockoutBindingHandlers {
    translate: KnockoutBindingHandler;
}