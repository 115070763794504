import { IEventAggregator } from "app/common/EventAggregator";
import { Enumerable } from "app/common/Enumerable";

import * as ko from 'knockout';

import { CartModel, CartItemModel, AddToCartResponse, WidgetsConfiguration } from "types/Models"

import { ProductAdapter } from "app/models/ProductAdapter";
import { ITranslationService } from "app/models/TranslationService";
import { ShoppingFacade, EventTypes } from "app/models/ShoppingFacade";
import { ILoadingIndicator } from "app/models/GlobalLoaderWidget";
import { WidgetErrorHandler } from "app/models/WidgetErrorHandler";

export interface IUpsellService {
    show(model: AddToCartResponse):void;
}

export class UpsellWidget implements IUpsellService {
   

    private readonly shoppingFacade: ShoppingFacade;
    private readonly eventAggregator: IEventAggregator;
    private readonly configuration: WidgetsConfiguration;
    private readonly loader: ILoadingIndicator;
    private readonly translationService: ITranslationService;

    addedProduct: KnockoutObservable<CartItemModel> = ko.observable(null);
    shoppingCartSummary: KnockoutObservable<CartModel> = ko.observable(null);
    upsellProduct: KnockoutObservable<ProductAdapter> = ko.observable(null);
    accessories: KnockoutObservableArray<ProductAdapter> = ko.observableArray([]);
    cartItemsLabel: KnockoutObservable<string> = ko.observable("");
    isUpsellProductAvailable: KnockoutObservable<boolean>;
    areAccessoriesAvailable: KnockoutObservable<boolean>;
    isUpsellSectionAvailable: KnockoutObservable<boolean>;
    isVisible: KnockoutObservable<boolean> = ko.observable(false);
	errorHandler: WidgetErrorHandler;
   
    constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, translationService: ITranslationService, configuration: WidgetsConfiguration, loader: ILoadingIndicator) {

        this.shoppingFacade = shoppingFacade;
        this.eventAggregator = eventAggregator;
        this.translationService = translationService;
        this.configuration = configuration;
        this.loader = loader;
	    this.errorHandler = new WidgetErrorHandler();
        this.isUpsellProductAvailable = ko.computed(() => {
            return this.upsellProduct() != null;
        });

        this.areAccessoriesAvailable = ko.computed(() => {
            return this.accessories() != null && this.accessories().length > 0;
        });

        this.isUpsellSectionAvailable = ko.computed(() => {
            return this.isUpsellProductAvailable() || this.areAccessoriesAvailable();
        });

        //this.upsellProduct.subscribe((v) => {
        //    if (v != null) {
        //        this.eventAggregator.publish(EventTypes.UpsellShow, v);
        //    }
        //});

        //this.accessories.subscribe((v) => {
        //    for (let item of v) {
        //        this.eventAggregator.publish(EventTypes.UpsellShow, item);
        //    }
        //});
    }

    show(model: AddToCartResponse) {
        this.cartItemsLabel(model.cart.totalQuantity === 1
            ? this.translationService.translate("/upsell/items/singular")
            : this.translationService.translate("/upsell/items/plural"));

        this.addedProduct(model.itemAdded);
        this.shoppingCartSummary(model.cart);

        var upsellProduct = model.upsellProduct != null
            ? new ProductAdapter(model.upsellProduct, this.translationService)
            : null;

        this.upsellProduct(upsellProduct);


        var accessories = new Enumerable(model.accessories)
            .select(p => new ProductAdapter(p, this.translationService))
            .where(p => p.isAvaillableForSale)
            .toArray();
        this.accessories(accessories);


        var trackingModel = accessories;
        if (upsellProduct != null) {
            trackingModel.push(upsellProduct);
        }

        this.eventAggregator.publish(EventTypes.UpsellShow, trackingModel);

        this.isVisible(true);
    }

  
    close() {
        this.isVisible(false);
    }
    
    addToCart(model : ProductAdapter): void {

		this.errorHandler.clear();
        this.loader.show();
        
        this.shoppingFacade.addToCart(model.articleNumber).then(r => {
		        this.close();
                this.loader.hide();
                try {
                    this.show(r);
				} catch (e) {
                    console.error(e);
                    throw e;
                }

            },
            e => {
				this.loader.hide();
	            this.errorHandler.handle(e.message);
                console.error(e);
            });
    }

    showCart(): void {
	    this.close();
	    this.eventAggregator.publish(EventTypes.NavigateToCart, null);
	}

	continueShopping(): void {
		this.close();
	};

    goToCheckout(): void {
        window.location.href = this.configuration.cartUrl;
    }
}