import { WidgetsConfiguration, KeyValuePair } from "types/Models";
import { Enumerable } from "app/common/Enumerable";

export interface ITranslationService {
    translate(key: string): string;
}

export class TranslationService implements ITranslationService  {
    private configuration: WidgetsConfiguration;
    private translations: Enumerable<KeyValuePair<string, string>>;

    constructor(configuration: WidgetsConfiguration) {
        this.configuration = configuration;
        this.translations = new Enumerable(configuration.translation);
    }

    translate(key: string): string {
        var translation = this.translations.firstOrDefault(x => x.key === key);

        if (translation == null) {
            return `No translation registered for: ${key}`;
        }

        return translation.value;
    }
}