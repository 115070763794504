import * as format from "string-template";

import { ProductModel, PriceModel } from "types/Models"

import { ITranslationService } from "app/models/TranslationService";
import Models = require("../../types/Models");
import StockInformationModel = Models.StockInformationModel;
import { ShipmentTime } from "types/Enums";

export class AdditionalTrackingData {
    public stockInformation: StockInformationModel;
    public brand: string;
    public productFamily: string;
    public currency: string;
}

function mapShipmentTimeToString(value: ShipmentTime): string {
    switch(value) {
        case ShipmentTime.Today:
            return "Today";
        case ShipmentTime.Future:
        default:
            return "Future";
    }
}

export class ProductAdapter extends AdditionalTrackingData {

    public articleNumber: string;
    public articleNumberFormatted: string;
    public image: string;
    public name: string;
    public type: string;
    public price: PriceModel;
    public listPriceFormatted: string;
    public placedPriceFormatted: string;
    public hasDiscount: boolean;
    public inStock: string;
    public inStockCount: number;
    public shipmentStatus: string;
    public isInStock: boolean;
    public stockCssClass: string;
    public isAvaillableForSale: boolean;
    public hasUnitOfMeasurePrice: boolean;
    public unitOfMeasurePriceFormatted: string;
    public unitOfMeasure: string;
    public freightLink: string;
    public hasFreightLink: boolean;
    public addToCartModalPriceText: string;
    constructor(productModel: ProductModel, translationService: ITranslationService) {
        super();
        this.articleNumber = productModel.articleNumber;
        this.articleNumberFormatted = productModel.articleNumberFormatted;
        this.image = productModel.image;
        this.name = productModel.name;
        this.type = productModel.type;
        this.brand = productModel.brand;
        this.productFamily = productModel.productFamily;
        this.stockInformation = productModel.stockInformation;
        this.freightLink = productModel.freightInfoLink;
        this.hasFreightLink = productModel.freightInfoLink != null && productModel.freightInfoLink !== "";
        if (productModel.price != null && productModel.isAvailableForSale) {
            this.price = productModel.price;
            this.listPriceFormatted = productModel.price.regularUnitPriceFormatted;
            this.placedPriceFormatted = productModel.price.discountedUnitPriceFormatted;
            this.hasDiscount = productModel.price.hasDiscount;
            this.isAvaillableForSale = true;
            this.hasUnitOfMeasurePrice = productModel.price.hasUnitOfMeasurePrice;
            this.unitOfMeasurePriceFormatted = productModel.price.pricePerUnitOfMeasureFormatted;
            this.unitOfMeasure = productModel.price.uniteOfMeasure;

        } else {
            this.isAvaillableForSale = false;
        }
	    
        this.inStock = format(translationService.translate(`/stockInformation/${productModel.stockInformation.stockStatus}`), productModel.stockInformation.stockQuantity);
        this.inStockCount = productModel.stockInformation.stockQuantity;
        this.isInStock = productModel.stockInformation.stockQuantity > 0;
        this.shipmentStatus =
            translationService.translate(`/shippment/${mapShipmentTimeToString(productModel.shipmentTime)}`);
        this.stockCssClass = "skfWidget__stockInformation--" + productModel.stockInformation.stockStatus;
        this.addToCartModalPriceText = productModel.addToCartModalPriceText;
    }
}