import Promise from "ts-promise";
import { TrackingEvent, IEventTracker } from "./Tracker";
import { TrackingModel } from "./TrackingModels";

const TRACKER_IMPRESION = "Impression";

export interface IModuleTracker {
    impression?: TrackerImpression;
    action: TrackerAction;
}

export abstract class Trackerable<TModel> {
    private tracker: IEventTracker;

    constructor(tracker: IEventTracker) {
        this.tracker = tracker;
    }

    protected trackInternal(event: TrackingEvent): Promise<boolean> {
        return this.tracker.track(event);
    }

    abstract track(actionName?: string, model?: TModel): Promise<boolean>;
}

export class TrackerAction extends Trackerable<TrackingModel> {
    track(actionName: string, model: TrackingModel): Promise<boolean> {
        return this.trackInternal(new TrackingEvent(actionName, model));
    }
}

export class TrackerImpression extends Trackerable<TrackingModel> {
    track(model: TrackingModel): Promise<boolean> {
        return this.trackInternal(new TrackingEvent(TRACKER_IMPRESION, model));
    }
}
