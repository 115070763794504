import * as ko from 'knockout';
import { CartModel, CartItemModel, PriceModel, Guid } from "types/Models";
import { AddToCartResponse, WidgetsConfiguration } from "types/Models";
import { StockStatus } from "types/Enums" 

import { ITranslationService } from "app/models/TranslationService";
import { IEventAggregator } from "app/common/EventAggregator";
import { ShoppingFacade } from "app/models/ShoppingFacade";

import { EventTypes } from "app/models/ShoppingFacade";
import { WidgetErrorHandler } from "app/models/WidgetErrorHandler";

export class CartWidget {

    private readonly  shoppingFacade: ShoppingFacade;
    private readonly  eventAggregator: IEventAggregator;
    private readonly configuration: WidgetsConfiguration;

    public grandTotal: KnockoutObservable<string>;
    public totalQuantity: KnockoutObservable<number>;
    public items: KnockoutObservableArray<CartItem>;
    public isVisible: KnockoutObservable<boolean> = ko.observable(false);
    public isEmpty: KnockoutObservable<boolean>;
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public shopUrl: string;
	errorHandler: WidgetErrorHandler;
    constructor(shoppingFacade: ShoppingFacade, eventAggregator: IEventAggregator, configuration: WidgetsConfiguration, shopUrl: string) {
        this.shoppingFacade = shoppingFacade;
        this.eventAggregator = eventAggregator;
        this.configuration = configuration;
	    this.errorHandler = new WidgetErrorHandler();
        this.totalQuantity = ko.observable(null);
        this.grandTotal = ko.observable(null);
        this.items = ko.observableArray([]);
        this.shopUrl = shopUrl;

        this.isEmpty = ko.computed(() => {
            return this.items().length === 0;
        });

        shoppingFacade.getCart().then(
            r => this.populateCartInforamtion(r),
			e => {
				this.errorHandler.handle(e.message);
				console.error(e);
			});

        this.eventAggregator.subscribe(
            EventTypes.Added,
            (r) => this.populateCartInforamtion((<AddToCartResponse>r).cart),
            this);

        this.eventAggregator.subscribe(
            EventTypes.NavigateToCart,
            () => this.navigateToCart(),
            this);

        ko.computed(() => {
            return this.items().length === 0;
        }).extend({ throttle: 1 }).subscribe((v) => {
            if (v) {
                this.isVisible(false);
            }
        });
    }

	toggleVisibility(): void {
        this.isVisible(!this.isVisible() && !this.isEmpty());
    };

    removeFromCart(item: CartItem) {
		this.isLoading(true);
	    this.errorHandler.clear();
        this.shoppingFacade.removeFromCart(item.id, item.articleNumber).then(r => {
            this.isLoading(false);
			this.populateCartInforamtion(r.cartModel);
        }, e => {
			this.isLoading(false);
	        this.errorHandler.handle(e.message);
            console.error(e);
        });
    };

    updateItemQuantityinCart(item: CartItem) {
		this.isLoading(true);
	    this.errorHandler.clear();
        this.shoppingFacade.updateItemQuantityinCart(item.id, item.articleNumber, item.quantity()).then(r => {
            this.isLoading(false);
            this.populateCartInforamtion(r.cartModel);
            this.totalQuantity(r.cartModel.totalQuantity);
        }, e => {
			this.isLoading(false);
	        this.errorHandler.handle(e.message);
            console.error(e);
        });
    };

    continueShopping(): void {
        if (this.shopUrl == null) {
            this.isVisible(false);
            return;
        }
     

        window.location.href = this.shopUrl;
    };

    navigateToCheckout(): void {         
        window.location.href = this.configuration.cartUrl;
    };

    hide(): void {
        this.isVisible(false);
    }

    private populateCartInforamtion(cart: CartModel): void {
        this.items([]);

        for (let cartItem of cart.items) {
            try {
                this.items.push(new CartItem(cartItem));
            } catch (e) {
                console.log(e);
            }
        }

        this.grandTotal(cart.grandTotalFormatted);
        this.totalQuantity(cart.totalQuantity);
    };

    private navigateToCart() {
        this.isVisible(true);
        window.scrollTo(0,0);
    };

}

export class CartItem {

    public articleNumber: string;
    public articleNumberFormatted: string;
    public id: Guid;
    public image: string;
    public inStock: StockStatus;
    public inStockCount: number;
    public maxQuantity: KnockoutObservable<number>;
    public maxQuantityRange: KnockoutObservable<number[]>;
    public name: string;
    public position: string;
    public price: PriceModel;
    public quantity: KnockoutObservable<number>;
    public type: string;
    public url: string;
    
	constructor(item: CartItemModel) {
        this.articleNumber = item.articleNumber;
        this.articleNumberFormatted = item.articleNumberFormatted;
        this.id = item.id;
        this.image = item.image;
        this.inStock = item.stockInformation.stockStatus;
        this.inStockCount = item.stockInformation.stockQuantity;
        this.maxQuantity = ko.observable(item.maxQuantity);
        this.maxQuantityRange = ko.observable(this.generateMaxQuantityRange(1, item.maxQuantity));
        this.name = item.name;
        this.position = item.position;
        this.price = item.price;
        this.quantity = ko.observable(item.quantity);
        this.type = item.type;
	    this.url = item.url;
    }
    
    generateMaxQuantityRange(from: number, maxQuantity: number) : number[] {
        let range = [];
        for (let i = from; i <= maxQuantity; i++) {
            range.push(i);
        }
        return range;
    }
}

