import { Bootstrapper } from "app/models/Bootstrapper";
import { CurrentScriptResolver } from "app/common/CurrentScriptResolver";

import "url-polyfill"

module SKF.App.Main {

	export class Init {
		onRequestCompleted: void;
		
        initialize() {
            let currentScript = CurrentScriptResolver.resolve();

            let urlString = currentScript.getAttribute("src");

            if (urlString.indexOf("http") !== 0) {
                urlString = window.location.href;
			}

	        var loadCss = true;
			if (currentScript.hasAttribute("data-load-css") && currentScript.getAttribute("data-load-css") !== "true") {
					loadCss = false;
			}
	      

            const url = new URL(urlString);
			new Bootstrapper().initialize(url.origin + "/", loadCss).then(() => { }, (e) => console.error(e));
        }
	}
}

new SKF.App.Main.Init().initialize();;
