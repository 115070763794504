import { WidgetsConfiguration } from "types/Models";
import { ApiResolver } from "app/common/ApiResolver";
import { UrlResolver } from "app/common/UrlResolver";
import Promise from "ts-promise";
import * as ko from 'knockout';
import { AbsoluteSrc, IKnockoutBindingHandlers } from "app/bindings/AbsoluteSrc";
import { Translate, ITranslateKnockoutBindingHandlers } from "app/bindings/Translate";
import { ClickedOutside, IClickedOutsideKnockoutBindingHandlers } from "app/bindings/ClickedOutside";
import { WidgetInitializationStrategy } from "app/models/WidgetInitializationStrategy";
import { LazyWidgetInitializationStrategy } from "app/models/LazyWidgetInitializationStrategy";
import { GlobalApi } from "app/models/GlobalApi";
import { TemplateLoader } from "app/common/ajax/TemplateLoader";
import { CssLoader } from "app/common/ajax/CssLoader";
import { EventAggregator } from "app/common/EventAggregator";
import { ShoppingServiceProxy } from "app/models/ShoppingServiceProxy";
import { ShoppingFacade } from "app/models/ShoppingFacade";
import { WidgetBuilder } from "app/models/WidgetBuilder";
import { TranslationService } from "app/models/TranslationService";
import { TrackerResolver } from "app/common/tracking/GlobalTracker";
import { TrackingHandler } from "app/common/tracking/TrackingHandler";

export class Bootstrapper {
    initialize(origin: string, loadCss: boolean): Promise<void> {
        var deferred = Promise.defer<void>();

        const shoppingService = new ShoppingServiceProxy(origin);

        const configurationLoadingPromise = shoppingService.getConfiguration();
        const templateLoadingPromise = new TemplateLoader().load(origin + "widget/assets/templates/template.html");
		if (loadCss) {
			new CssLoader().load(origin + "widget/Build/style.bundle.css");
	    }

	    Promise.all<any>([templateLoadingPromise, configurationLoadingPromise]).then(results => {
                try {
                    this.initializeApi(origin, shoppingService, <WidgetsConfiguration>results[1]);
                } catch (e) {
                    deferred.reject(e);
                }

                deferred.resolve(null);
            },
            error => {
                console.error(error);
                deferred.reject(error);
            });

        return deferred.promise;
    }

    private initializeApi(origin: string, shoppingService: ShoppingServiceProxy, configuration: WidgetsConfiguration)  {
        const api = ApiResolver.ensureAvailable();

        this.initLitiumContext(configuration);

        if (api.isInitialized()) {
            throw new Error("Api can be initialized only once.");
        }
        const eventAggregator = new EventAggregator();
        const urlResolver = new UrlResolver(origin, "/widget/");
        const translationService = new TranslationService(configuration);
        const shoppingFacade = new ShoppingFacade(shoppingService, eventAggregator);

        new TrackingHandler(eventAggregator, TrackerResolver.init());

        (<IKnockoutBindingHandlers>ko.bindingHandlers).absoluteSrc = new AbsoluteSrc(urlResolver);
        (<ITranslateKnockoutBindingHandlers>ko.bindingHandlers).translate = new Translate(translationService);
        (<IClickedOutsideKnockoutBindingHandlers>ko.bindingHandlers).clickedOutside = new ClickedOutside();

        const builder = new WidgetBuilder(shoppingFacade, eventAggregator, translationService, urlResolver, configuration);
        
        const widgetsInitilizer = new WidgetInitializationStrategy(builder, configuration);
        
        if (api.initializer != null) {
            const currentInitializer = <LazyWidgetInitializationStrategy>api.initializer;
            widgetsInitilizer.initializePending(currentInitializer.widgets);
        }

        const newApi = new GlobalApi(true, eventAggregator, shoppingService);
        newApi.initializer = widgetsInitilizer;

        ApiResolver.initialize(newApi);

        this.triggerApiAvailableEvent();
    }
    private initLitiumContext(configuration: WidgetsConfiguration) {

        window.LitiumContext = window.LitiumContext || { "channelSystemId": configuration.channelId };
    };
    private triggerApiAvailableEvent() {
        const name = "skf_widget_api_ready";

        const event = document.createEvent('Event');
        event.initEvent(name, true, true);
        document.dispatchEvent(event);
    };
}