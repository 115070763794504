import * as ko from 'knockout';

export interface ILoadingIndicator {
    show(): void;
    hide(): void;
}

export class GlobalLoaderWidget implements ILoadingIndicator {
    
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);

    show() : void {
        this.isLoading(true);
    };

    hide() : void {
        this.isLoading(false);
    };
}