import { Deferred, Promise } from "ts-promise";
import { ProductModel } from "types/Models";
import { IShoppingServiceProxy } from "app/models/ShoppingServiceProxy";
import { Enumerable } from "app/common/Enumerable";
import { IEventAggregator } from "app/common/EventAggregator";
import { EventTypes } from "app/models/ShoppingFacade";
class QueuedItem {
    constructor(code: string, deferred: Deferred<ProductModel>) {
        this.code = code;
        this.deferred = deferred;
    }

    code: string;
    deferred: Deferred<ProductModel>;
}

export class AggregatedProductRequestService {
    private eventAggregator;
    private service: IShoppingServiceProxy;
    private queue = new Enumerable<QueuedItem>([]);
    private timeOutHandler: ReturnType<typeof setTimeout>;
    private firstRequstTime: number;
    private queueInterval: number = 25;

    constructor(service: IShoppingServiceProxy, eventAggregator: IEventAggregator ) {
        this.eventAggregator = eventAggregator;
        this.service = service;
    }

    getProduct(code: string): Promise<ProductModel> {

        const deferred = Promise.defer<ProductModel>();
        const queueItem = new QueuedItem(code, deferred);

        if (!this.timeOutHandler) {
            this.firstRequstTime = new Date().getTime();
            this.shedule();
            this.queue.push(queueItem);
        } else {
            const currentTime = new Date().getTime();
            if (this.firstRequstTime + 4 * this.queueInterval < currentTime) {
                this.firstRequstTime = new Date().getTime();
                this.shedule();
            } else {
                this.shedule();
            }

            this.queue.push(queueItem);
        }

        return deferred.promise;
    }

    private shedule(): void {

        clearTimeout(this.timeOutHandler);

        this.timeOutHandler = setTimeout(() => {
            this.getProducts();
        }, this.queueInterval);
    }

    private getProducts() {
        clearTimeout(this.timeOutHandler);

        const itemsToResolve = this.queue.excechange();

        if (itemsToResolve.count() === 0) {
            return;
        }

        const productsToRetrive = itemsToResolve.select(c => c.code);

        this.service.getProducts(productsToRetrive.toArray()).then((response) => {
            let returnedItems = new Enumerable(response);

            this.eventAggregator.publish(EventTypes.ProductsLoaded, returnedItems.toArray());
			itemsToResolve.forEach(x => {

				const product = returnedItems.firstOrDefault(r => {

					//console.log(typeof r.articleNumber);
					//console.log(typeof x.code);
					//console.log( r.articleNumber);
					//console.log( x.code);
					//console.log("-"+r.articleNumber+"-");
					//console.log("-" + x.code + "-");
					//console.log(r.articleNumber === x.code);
					return r.articleNumber === x.code;
				});

				//	console.log(product);
				//console.log(returnedItems);

                x.deferred.resolve(product);
            }),
                error => {
                    itemsToResolve.forEach(x => {
                        x.deferred.reject(error);
                    });
                }
        });

    }

}
