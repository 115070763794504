import * as ko from "knockout";

import { WidgetDefinition } from "app/models/WidgetDefinition";
import { IWidgetFactory } from "app/models/IWidgetFactory";
import { GlobalLoaderWidget } from "app/models/GlobalLoaderWidget";

export class GlobalLoaderWidgetFactory implements IWidgetFactory {

    build(definition: WidgetDefinition): GlobalLoaderWidget {
        
        try {

            const element = document.getElementById(definition.containerId);

            element.innerHTML = document.getElementById("skfWidget__loader__global").innerText;
            element.id = "";
            element.firstElementChild.id = definition.containerId;
            element.parentNode.replaceChild(element.firstElementChild, element);

            const model = new GlobalLoaderWidget();

            ko.applyBindings(model, document.getElementById(definition.containerId));

            return model;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}