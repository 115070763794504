

export class CssLoader {

	load(path: string) {

		var link = document.createElement("link");
		link.href = path;
		link.rel = "stylesheet";
		link.setAttribute('type', 'text/css');
		document.getElementsByTagName("head")[0].appendChild(link);
	};
}