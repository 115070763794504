import { WidgetDefinition } from "app/models/WidgetDefinition";
import { IWidgetInitializationStrategy } from "app/models/IWidgetInitializationStrategy";
import { WidgetBuilder } from "app/models/WidgetBuilder";
import { WidgetsConfiguration } from "types/Models";

export class WidgetInitializationStrategy implements IWidgetInitializationStrategy {

    builder: WidgetBuilder;
    configuraion: WidgetsConfiguration;

    constructor(builder: WidgetBuilder, configuraion: WidgetsConfiguration) {
        this.builder = builder;
        this.configuraion = configuraion;
    }

    initialize(widget: WidgetDefinition) {
        if (widget == null) {
            throw new Error("Argument null exception. Widget need to be provided");
        }

        this.builder.build(widget);
    }

    initializePending(definitions: WidgetDefinition[]): void {
        for (let i = 0; i < definitions.length; i++) {
            this.initialize(definitions[i]);
        }
    }
}
