import Promise from "ts-promise";
import { ProductModel } from "types/Models";
import { CartModel } from "types/Models";
import { Guid } from "types/Models";
import { AddToCartResponse } from "types/Models";
import { CartItemChangeResponse } from "types/Models";
import { IEventAggregator } from "app/common/EventAggregator";
import { IShoppingServiceProxy } from "app/models/ShoppingServiceProxy";
import { AggregatedProductRequestService } from "app/models/AggregatedProductRequestService";


export enum EventTypes {
    "FetchedCart" = "FetchedCart",
    "UpsellShow" = "UpsellShow",
    "ProductsLoaded" = "ProductsLoaded",
    "Shown" = "Shown",
    "Add" = "Add",
    "Added" = "Added",
    "Removed" = "Removed",
    "QuantityChanged" = "QuantityChanged",
    "NavigateToCart" = "NavigateToCart"
}

export class ShoppingFacade {
    private service: IShoppingServiceProxy;
    private eventAggregator: IEventAggregator;
    private aggregatedProductRequestService: AggregatedProductRequestService;

    constructor(service: IShoppingServiceProxy, eventAggregator: IEventAggregator) {
        this.service = service;
        this.eventAggregator = eventAggregator;
        this.aggregatedProductRequestService = new AggregatedProductRequestService(service, eventAggregator);
    }
    
    getProduct(code: string): Promise<ProductModel> {
        return this.aggregatedProductRequestService.getProduct(code).then((r) => {
            this.eventAggregator.publish(EventTypes.Shown, r);
            return r;
        });
    }

    getCart(): Promise<CartModel> {
        return this.service.getCart().then((r) => {
            this.eventAggregator.publish(EventTypes.FetchedCart, r);
            return r;
        });
    }

    addToCart(articleNumber: string): Promise<AddToCartResponse> {
        return this.service.addToCart(articleNumber).then((r) => {
            this.eventAggregator.publish(EventTypes.Added, r);
            return r;
        });
    }

    removeFromCart(identifier: Guid, articleNumber: string): Promise<CartItemChangeResponse> {
        return this.service.removeFromCart(identifier, articleNumber).then(r => {
            this.eventAggregator.publish(EventTypes.Removed, r);
            return r;
        });
    }

    updateItemQuantityinCart(identifier: Guid, articleNumber: string, quantity: number): Promise<CartItemChangeResponse> {
        return this.service.updateItemQuantityinCart(identifier, articleNumber, quantity).then(r => {
            this.eventAggregator.publish(EventTypes.QuantityChanged, r);
            return r;
        });
    }

	loginStatus() :Promise<boolean> {
		return this.service.loginStatus();
	}

}
