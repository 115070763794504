import { IEventTracker } from "./Tracker";
import { IModuleTracker, TrackerAction, TrackerImpression } from "./ModuleTracker";

export class ProductTracker implements IModuleTracker {
    public impression: TrackerImpression;
    public action: TrackerAction;

    constructor(tracker: IEventTracker) {
        this.impression = new TrackerImpression(tracker);
        this.action = new TrackerAction(tracker);
    }
}