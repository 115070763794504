
import * as ko from 'knockout';
import Promise from "ts-promise";
import { AjaxRequestSender } from "app/common/ajax/AjaxRequestSender";
import { IRequestSender } from "app/common/ajax/AjaxRequestSender";


export class AjaxProxy<TRequest, TResponse> {

	isMethodExecuting: KnockoutObservable<boolean>;
	methodSender: IRequestSender;
    defaultTimeout = 0;
    method;
    responseType: string

    constructor(
        method: string,
        serviceUrl: string,
        responseType: string = "string"
    ) {
        this.method = method;
        this.isMethodExecuting = ko.observable(false);
        this.responseType = responseType;
        this.methodSender = new AjaxRequestSender(serviceUrl);
	}

    execute(input: TRequest): Promise<TResponse> {

        var query = null;
        if (input != null) {
            query = JSON.stringify(input);
        }
        
        var promise = this.methodSender.send<TResponse>(this.method, query, "string", false);

        promise.done(r => {
            this.isMethodExecuting(false);
        }, r => {
            this.isMethodExecuting(false);
        });

        return promise;
    }

	abort() {
		this.methodSender.abort();
	}
}