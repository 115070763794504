import { ProductTracker } from "./ProductTracker";
import { CartTracker } from "./CartTracker";
import { IEventTracker, GoogleTracker  } from "./Tracker";

declare global {
    interface Window { SKFTRACKER: any; }
}

export class GlobalTracker {
    cartTracker: CartTracker;
    productTracker: ProductTracker;
    constructor(tracker: IEventTracker) {
        this.cartTracker = new CartTracker(tracker);
        this.productTracker = new ProductTracker(tracker);
    }
}

export class TrackerResolver {
    static init(): GlobalTracker {
        window.SKFTRACKER = window.SKFTRACKER || {};
        window.SKFTRACKER.WIDGET = window.SKFTRACKER.WIDGET  || new GlobalTracker(new GoogleTracker());
        return window.SKFTRACKER.WIDGET;
    }
}

