
import Promise from "ts-promise";

export interface IRequestSender {
    send<T>(method: string, content: any, responseType?: string, withCredentials?: boolean): Promise<T>;
	abort();
}

export class AjaxRequestSender implements IRequestSender {
	private serviceUrl;
	private httpRequest: XMLHttpRequest;

	constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
	}

    public send<T>(method: string, content: any = null, responseType: string = "json", withCredentials: boolean = true) : Promise<T> {

	    var deferred = Promise.defer<T>();

		this.httpRequest = new XMLHttpRequest();
      
		this.httpRequest.onreadystatechange = () => {

            if (this.httpRequest.readyState === XMLHttpRequest.DONE && this.httpRequest.status === 200) {
                let result = this.httpRequest.response;

                if (responseType === "json" && result != null && result !== "") {
                    result = JSON.parse(result);
                }

                deferred.resolve(result);
	            return;
            }

			if (this.httpRequest.readyState === XMLHttpRequest.DONE && this.httpRequest.status === 404) {
				deferred.resolve(null);
			    return;
            }
         
            if (this.httpRequest.readyState === XMLHttpRequest.DONE && this.httpRequest.status !== 200) {
				deferred.reject(JSON.parse(this.httpRequest.response));
				return;
			}
        };

        this.httpRequest.open(method, this.serviceUrl, true);
        this.httpRequest.withCredentials = withCredentials;
	    this.httpRequest.setRequestHeader("Content-type", "application/json");
		this.httpRequest.setRequestHeader("ChannelId", "application/json");
		this.httpRequest.setRequestHeader("litium-request-context", JSON.stringify(window.LitiumContext || {}));

        if (content != null) {
			this.httpRequest.send(content);
        } else {
            this.httpRequest.send();
        }
        
	    return deferred.promise;
	}

	abort() {
		if (this.httpRequest != null) {
			this.httpRequest.abort();
		}
	}

}